import React from "react";

export default function () {

	function iframeOnLoad(){
		const frame		=	document.querySelector('iframe.google_map')
	//	frame.width		=	frame.parentElement.offsetWidth;
	//	frame.width		=	window.innerWidth;
		if( window.innerHeight < frame.height){
			frame.height	=	window.innerHeight-250;
		}
		return this;
	}
	const google_map_embed_src	=	[
	//	'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d92937.77463261852!2d-72.68581252157554!3d43.28692447874964!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e1b4028378dadb%3A0x9b1b0a80bea1b1a4!2sChester%2C%20VT%2005143!5e0!3m2!1sen!2sus!4v1603014908588!5m2!1sen!2sus',
		'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d742330.3747776786!2d-73.11148079787154!3d43.197059413862185!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e1b4028378dadb%3A0x9b1b0a80bea1b1a4!2sChester%2C%20VT%2005143!5e0!3m2!1sen!2sus!4v1603098545273!5m2!1sen!2sus',
	//	'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1529983.5161754503!2d-73.6549547219066!3d43.376069417969106!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e1b4028378dadb%3A0x9b1b0a80bea1b1a4!2sChester%2C%20VT%2005143!5e0!3m2!1sen!2sus!4v1603098412292!5m2!1sen!2sus',
		'key=AIzaSyDc6TycB4NFrrHb2tmSMZ4_gUwUkezh1ps'
	].join('&');

	return (
  	<div style={{
  		height: '400px',
  		width: '100%',
  	}}>
	  <iframe
	  	title="Blackstone Vermont Map"
		className="google_map"
		width="100%"
		height="100%"
		frameBorder="0"
		style={{border:0}}
		src={google_map_embed_src}
		allowFullScreen
		onLoad={iframeOnLoad}
	  ></iframe>
	</div>
	);
}